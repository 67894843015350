/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable */
import * as LocalStore from 'store';
import intl from 'react-intl-universal';
import * as FileSaver from 'file-saver';
import CryptoJS from 'crypto-js';
import cryptoPolyfill from 'crypto-browserify';
import { toast } from 'react-toastify';

import * as XLSX from 'xlsx/dist/xlsx.mini.min';
import {
  setCompanyReferenceParameterSet,
  setCompanySettings,
  setCompany,
  setCompanyLabels,
  setCompanyLocalUnits,
  setCompanyTableRules,
} from '../actions/setCompany';
import { setRbacDetails } from '../actions/setRbacDetails';
import { store } from '../store';
import { apiGet } from './api';
import { addDayToDate, formatDateWithHyphenReverse, simplyFormatWithColon } from './hull/date';

function loadScript(url) {
  return new Promise(function (resolve, reject) {
    let script = document.createElement('script');
    script.src = url;
    script.async = false;
    script.onload = function () {
      resolve(url);
    };
    script.onerror = function () {
      reject(url);
    };
    document.body.appendChild(script);
  });
}
export const loadScripts = (scripts, callback) => {
  // save all Promises as array
  let promises = [];
  scripts.forEach(function (url) {
    promises.push(loadScript(url));
  });

  Promise.all(promises)
    .then(function () {
      callback(true);
    })
    .catch(function (script) {
      console.log('load script failed');
      callback(false);
    });
};
export const numberWithDots = (x) => x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
export const deleteEmpty = (obj) => {
  Object.keys(obj).forEach((k) => ((!obj[k] && obj[k] !== undefined) || !obj[k]) && delete obj[k]);
  return obj;
};
export const toString = (obj) =>
  Object.entries(obj)
    .map(([k, v], index) => {
      if (v === null || v === undefined || v.toString() === 'Invalid Date' || v === '') {
        return '';
      }
      return `${k}=${v}${index + 1 === Object.entries(obj).length ? '' : '&'}`;
    })
    .join('');
export const objectToString = (obj, shouldEncodeURI = true, addAmpersandOnFirst = true) =>
  Object.entries(obj)
    .map(([k, v], index) => {
      if (v === null || v === undefined || v.toString() === 'Invalid Date' || v === '') {
        return '';
      }
      if (Array.isArray(v)) {
        return shouldEncodeURI ? v.map((item) => `&${k}=${item}`).join('') : v.map((item) => `&${k}=${item}`);
      }
      return `${!addAmpersandOnFirst && index === 0 ? '' : '&'}${k}=${shouldEncodeURI ? encodeURIComponent(v) : v}`;
    })
    .join('');
export const groupBy = (xs, key) =>
  xs?.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const groupByMultipleProps = (array, f) => {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};
export const changeCompanySelection = (id, requestOptions = { headers: {} }) => {
  return new Promise(async (resolve, reject) => {
    try {
      await store.dispatch(setCompany(id));
      LocalStore.set('company', id);
      const referenceParameters = await apiGet({ url: 'company/reference-parameter-set/', options: requestOptions });
      const companySettings = await apiGet({ url: 'company/setting/', options: requestOptions });
      const rbacDetails = await apiGet({ url: '/rbac/v2/user/detail/', options: requestOptions });
      Promise.all([referenceParameters, companySettings, rbacDetails])
        .then(([parametersRes, settingsRes, rbacRes]) => {
          if (rbacRes) {
            store.dispatch(setRbacDetails(rbacDetails));
          }
          if (parametersRes) {
            parametersRes.parameters[parametersRes.parameters.findIndex((x) => x.name === 'incompatibleOrderProperties')].dataType =
              'IncompatibleOrderProperties';

            /* TEST */
            const distanceScenarioIdFoundIndex = parametersRes.parameters.findIndex((x) => x.name === 'distanceScenarioId');
            if (distanceScenarioIdFoundIndex) {
              parametersRes.parameters[distanceScenarioIdFoundIndex] = {
                ...parametersRes.parameters[distanceScenarioIdFoundIndex],
                dataType: 'String',
                group: 'standard',
                masterDataType: 'planning',
                name: 'distanceScenarioId',
                type: 'MasterDataIdSelect',
                masterDataIdSelectPlusActive: false,
                masterDataIdSelectOnly: true,
              };
            }
            /* TEST */

            store.dispatch(setCompanyReferenceParameterSet(parametersRes.parameters));
          }
          if (settingsRes.results) {
            store.dispatch(
              setCompanySettings(
                settingsRes.results.reduce((acc, curr) => {
                  if (curr.name === 'applicationContext') return acc;
                  return { ...acc, [curr.name]: curr.value };
                }, {}),
              ),
            );
            let index = settingsRes.results.findIndex((x) => x.name === 'capacityLabels');
            if (index >= 0) {
              store.dispatch(setCompanyLabels(settingsRes.results[index].value));
            } else {
              store.dispatch(setCompanyLabels(null));
            }
            const planUpdateSentFieldRestriction = settingsRes.results.find((x) => x.name === 'planUpdateSentFieldRestriction');
            if (planUpdateSentFieldRestriction && planUpdateSentFieldRestriction.value === '1') {
              LocalStore.set('planUpdateSentFieldRestriction', true);
            } else {
              LocalStore.set('planUpdateSentFieldRestriction', false);
            }
            /* edit permiision setter */
            const editPermissionByCompany = settingsRes.results.find((x) => x.name === 'editPermissionByCompany');
            if (
              editPermissionByCompany &&
              editPermissionByCompany.value &&
              Object.keys(JSON.parse(editPermissionByCompany.value)).length > 0
            ) {
              const permissions = JSON.parse(editPermissionByCompany.value);
              LocalStore.set('editPermissionByCompany', permissions);
            } else {
              LocalStore.set('editPermissionByCompany', null);
            }

            index = settingsRes.results.findIndex((x) => x.name === 'regionalPrefs');
            if (index >= 0) {
              store.dispatch(setCompanyLocalUnits(JSON.parse(settingsRes.results[index].value)));
            } else {
              store.dispatch(setCompanyLocalUnits(null));
            }

            index = settingsRes.results.findIndex((x) => x.name === 'enableVehicleWorkloadHistory');
            if (index >= 0) {
              store.dispatch(
                setCompanyTableRules({
                  enableVehicleWorkloadHistory: settingsRes.results[index].value === 'true',
                }),
              );
            } else {
              store.dispatch(setCompanyTableRules(false));
            }
          }
          resolve();
        })
        .catch((error) => {
          console.log('CatchTest', error);
          reject();
        });
    } catch (error) {
      console.log('CatchTest', error);
      reject();
    }
  });
};
export const prepareToApi = (obj, joinChar = ';') => {
  const objTemp = { ...obj };
  Object.keys(objTemp).forEach((k) => {
    try {
      if (typeof objTemp[k] === 'boolean') {
        objTemp[k] = objTemp[k] ? '1' : '0';
      } else if (objTemp[k].value) {
        objTemp[k] = objTemp[k].value;
      } else if (Array.isArray(objTemp[k]) && objTemp[k].length === 0) {
        objTemp[k] = '';
      } else if (typeof objTemp[k] === 'object' && objTemp[k][0].value) {
        objTemp[k] = objTemp[k].map((elem) => elem.value).join(joinChar);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  });

  return objTemp;
};
export const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h} ${intl.get('hour-short')} ${m} ${intl.get('minute-short')}`;
};
export const getUniqueBy = (arr, prop) => {
  const set = new Set();
  return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
};
export const deepCopy = (aObject) => {
  // Prevent undefined objects
  if (!aObject) return aObject;

  let bObject = Array.isArray(aObject) ? [] : {};

  let value;
  for (const key in aObject) {
    // Prevent self-references to parent object
    // if (Object.is(aObject[key], aObject)) continue;

    value = aObject[key];

    bObject[key] = typeof value === 'object' ? deepCopy(value) : value;
  }

  return bObject;
};
export const sortByPropertyType = {
  String: (arr, p, o = 1) => [...arr].sort((a, b) => (!a[p] ? 1 : !b[p] ? -1 : a[p].localeCompare(b[p]) * o)),
  Number: (arr, p, o = 1) =>
    [...arr].sort((a, b) => (!a[p] && a[p] !== 0 ? 1 : !b[p] && b[p] !== 0 ? -1 : (parseFloat(a[p]) - parseFloat(b[p])) * o)),
  Time: (arr, p, o = 1) => [...arr].sort((a, b) => (!a[p] ? 1 : !b[p] ? -1 : (new Date(a[p]).getTime() - new Date(b[p]).getTime()) * o)),
};
export const sortDataWithGivenOptions = (accessor, options, data) => {
  let tempData = [...data];
  if (options.sortCount === 0) {
    // return original data
    return tempData;
  } else if (options.desc) {
    // descending order
    return sortByPropertyType[options.sortDataType](tempData, accessor, -1);
  } else {
    // ascending order
    return sortByPropertyType[options.sortDataType](tempData, accessor, 1);
  }
};

const formatNumbers = (ws) => {
  var range = XLSX.utils.decode_range(ws['!ref']);
  for (var R = range.s.r; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
      if (!cell || cell.t != 'n') continue; // only format numeric cells

      if (cell.v % 1 === 0) cell.z = '0';
      else cell.z = '0.00';
    }
  }
  return ws;
};
export const excelExport = ({ data, fileName, sheetNames, headers, readHeadersFromSheetNames = false }) => {
  let wb = { Sheets: {}, SheetNames: [] };
  sheetNames.forEach((name, i) => {
    if (data[i]) {
      const excelHeaders = readHeadersFromSheetNames ? headers[name] : headers;
      const ws = XLSX.utils.json_to_sheet(
        [
          excelHeaders.map((h) => (h.noTranslate ? h.accessor : intl.get(h.accessor))),
          ...data[i].map((row) =>
            excelHeaders.map((header) => {
              return row[header.accessor];
            }),
          ),
        ],
        {
          skipHeader: true,
          raw: false,
        },
      );
      wb.Sheets[name] = formatNumbers(ws);
      wb.SheetNames.push(name);
    }
  });
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, fileName + fileExtension);
};
export const mapUnplannedOrdersKeysToPlanOrder = (orders) =>
  orders.map((o) => ({
    lat: o.latitude,
    lng: o.longitude,
    ArrivalTime: ': :',
    Box: o.box,
    CustomerNote: o.note,
    DepartureTime: ': :',
    DirectLocationId: o.locationId,
    DirectLocationName: o.locationName,
    Distance: intl.get('waitingReoptimization'),
    DistanceFromStartLocation: '',
    DistanceLocationId: o.locationId,
    DistanceLocationName: o.locationName,
    DistanceType: '',
    EarliestStartTime: ': :',
    LatestFinishTime: ': :',
    LTLPriceOfOrderFromDepot: '',
    OptiyolScenarioId: '',
    OrderID: o.orderId,
    OrderLabels: '',
    Pallet: o.pallet,
    PaymentAmount: '',
    PaymentType: '',
    PhoneNumber: o.phoneNumber,
    PlannedCrossdockLTLCostOfOrder: '',
    PriorityLevel: '',
    RequiresPayment: '',
    ServiceTime: '',
    ServiceType: o.serviceType,
    StartTime: '',
    TimeWindowViolation: '',
    ToLocationAddress: o.locationAddress,
    ToLocationId: o.locationId,
    ToLocationLat: o.latitude,
    ToLocationLong: o.longitude,
    ToLocationName: o.locationName,
    TourId: '',
    TrackerCode: o.trackerCode,
    TravelTime: '',
    Volume: o.volume,
    WaitingTime: '',
    Weight: o.weight,
    Size5: o.size5,
    Size6: o.size6,
  }));
export const createImaginaryLocationForVehicle = async (newVehicle, latAccessor, lngAccessor, idAccessor, givenOrderId, givenRouteId) => {
  let imaginaryLocation = null;
  if (newVehicle[latAccessor] && newVehicle[lngAccessor]) {
    // yeni bir lokasyon oluştur ve ekle
    imaginaryLocation = mapUnplannedOrdersKeysToPlanOrder([
      {
        latitude: newVehicle[latAccessor],
        longitude: newVehicle[lngAccessor],
        locationName: `${newVehicle.vehicleId}-Start`,
        orderId: givenOrderId,
        trackerCode: givenOrderId,
        serviceType: '',
        box: '0',
        pallet: '0',
        volume: '0',
        weight: '0',
        size5: '0',
        size6: '0',
      },
    ])[0];
  } else {
    // lokasyon idsinden çek
    if (newVehicle[idAccessor]) {
      const locationResponse = await apiGet({ url: `v1/locations/${newVehicle[idAccessor]}/` });
      if (locationResponse?.lat) {
        imaginaryLocation = mapUnplannedOrdersKeysToPlanOrder([
          {
            ...locationResponse,
            orderId: givenOrderId,
            trackerCode: givenOrderId,
            StartTime: '',
            ArrivalTime: '',
            DepartureTime: '',
            serviceType: '',
            box: '0',
            pallet: '0',
            volume: '0',
            weight: '0',
            size5: '0',
            size6: '0',
          },
        ])[0];
      } else imaginaryLocation = null;
    }
  }
  if (imaginaryLocation) {
    imaginaryLocation = {
      ...imaginaryLocation,
      VehicleId: newVehicle.vehicleId,
      VehiclePlateNumber: newVehicle.plateNumber || newVehicle.vehicleId,
      RouteId: givenRouteId,
    };
  }
  return imaginaryLocation;
};
const getSumOfGivenKey = (arr, key) => {
  return arr.reduce((acc, curr) => {
    return acc + parseFloat(curr[key]);
  }, 0);
};
const getCapacityNewValues = (arr, route) => {
  const keys = ['Weight', 'Volume', 'Box', 'Palette', 'Pallet', 'Size5', 'Size6'];
  let capacities = {};
  const values = [];
  keys.forEach((key) => {
    const sumOfOrders = getSumOfGivenKey(arr, key);
    const newUtilization = sumOfOrders === 0 ? 0 : (sumOfOrders / parseFloat(route[`Vehicle${key}Cap`])) * 100;
    values.push(isNaN(newUtilization) ? 0 : newUtilization);
    capacities = {
      ...capacities,
      [`Vehicle${key}Total`]: isNaN(sumOfOrders) ? 0 : sumOfOrders.toFixed(2),
      [`Vehicle${key}Utilization`]: isNaN(newUtilization) ? 0 : newUtilization.toFixed(2),
    };
  });
  capacities = { ...capacities, MaximumUtilization: Math.max(...values).toFixed(2) };
  return capacities;
};
const getOrderCount = (arr) => {
  return arr.filter((item) => item.OrderID !== 'Start' && item.OrderID !== 'End').length;
};
const getStopCount = (arr) => {
  let lastPoint = { lat: 0, lng: 0 };
  return arr
    .filter((item) => item.OrderID !== 'Start' && item.OrderID !== 'End')
    .reduce((acc, curr, index) => {
      if (acc.length > 0) {
        if (lastPoint.lat === curr.lat && lastPoint.lng === curr.lng) {
          // aynı nokta
          acc[acc.length - 1].push(curr);
        } else {
          lastPoint = curr;
          acc.push([curr]);
        }
      } else {
        lastPoint = curr;
        acc.push([curr]);
      }
      return acc;
    }, []).length;
};
const getMaxValueOfArrayOfObjectsWithAccessor = (arr, accessor) => {
  return Math.max.apply(
    Math,
    arr.map((o) => {
      return parseFloat(o[accessor]);
    }),
  );
};
const calculateRouteValuesWithPickup = ({ routeSummary, routeDetails, routes }) => {
  const allRouteDetails = [];
  const result = routeSummary.map((route) => {
    const tempRouteDetails = [];
    const foundRouteDetails = routeDetails.filter((row) => row.RouteId === route.RouteId);
    const calculateVehicleCurrentSize = (vehicleAccessor, sizeAccessor, serviceTypeMultiplier, i) => {
      return (
        parseFloat(tempRouteDetails[i - 1][vehicleAccessor] || 0) +
        parseFloat(foundRouteDetails[i][sizeAccessor] || 0) * serviceTypeMultiplier
      )
        .toFixed(2)
        .toString();
    };
    const calculateVehicleInitialSize = (sizeAccessor) => {
      let total = 0;
      foundRouteDetails.forEach((o) => {
        if (o.ServiceType === 'DELIVERY' && !foundRouteDetails.find((x) => x.OrderID === o.OrderID && x.ServiceType === 'PICKUP')) {
          total += parseFloat(o[sizeAccessor]);
        }
      });
      return total.toFixed(2).toString();
    };
    for (let i = 0; i < foundRouteDetails.length; i++) {
      if (i === 0) {
        // first element
        tempRouteDetails.push({
          ...foundRouteDetails[i],
          VehicleBox: calculateVehicleInitialSize('Box'),
          VehiclePalette: calculateVehicleInitialSize('Palette'),
          VehiclePallet: calculateVehicleInitialSize('Pallet'),
          VehicleSize5: calculateVehicleInitialSize('Size5'),
          VehicleSize6: calculateVehicleInitialSize('Size6'),
          VehicleVolume: calculateVehicleInitialSize('Volume'),
          VehicleWeight: calculateVehicleInitialSize('Weight'),
        });
      } else {
        const serviceTypeMultiplier = foundRouteDetails[i].ServiceType === 'DELIVERY' ? -1 : 1;
        tempRouteDetails.push({
          ...foundRouteDetails[i],
          VehicleBox: calculateVehicleCurrentSize('VehicleBox', 'Box', serviceTypeMultiplier, i),
          VehiclePalette: calculateVehicleCurrentSize('VehiclePalette', 'Palette', serviceTypeMultiplier, i),
          VehiclePallet: calculateVehicleCurrentSize('VehiclePallet', 'Pallet', serviceTypeMultiplier, i),
          VehicleSize5: calculateVehicleCurrentSize('VehicleSize5', 'Size5', serviceTypeMultiplier, i),
          VehicleSize6: calculateVehicleCurrentSize('VehicleSize6', 'Size6', serviceTypeMultiplier, i),
          VehicleVolume: calculateVehicleCurrentSize('VehicleVolume', 'Volume', serviceTypeMultiplier, i),
          VehicleWeight: calculateVehicleCurrentSize('VehicleWeight', 'Weight', serviceTypeMultiplier, i),
        });
      }
    }
    const maxSizes = [
      'VehicleWeight',
      'VehicleVolume',
      'VehicleBox',
      'VehiclePalette',
      'VehiclePallet',
      'VehicleSize5',
      'VehicleSize6',
    ].map((s) => getMaxValueOfArrayOfObjectsWithAccessor(tempRouteDetails, s));
    let capacities = {};
    const keys = ['Weight', 'Volume', 'Box', 'Palette', 'Pallet', 'Size5', 'Size6'];
    const values = [];
    keys.forEach((key, index) => {
      const newUtilization = maxSizes[index] === 0 ? 0 : (maxSizes[index] / parseFloat(route[`Vehicle${key}Cap`])) * 100;
      values.push(isNaN(newUtilization) ? 0 : newUtilization);
      capacities = {
        ...capacities,
        [`Vehicle${key}Total`]: isNaN(maxSizes[index]) ? 0 : maxSizes[index].toFixed(2),
        [`Vehicle${key}Utilization`]: isNaN(newUtilization) ? 0 : newUtilization.toFixed(2),
      };
    });
    capacities = { ...capacities, MaximumUtilization: Math.max(...values).toFixed(2) };
    allRouteDetails.push(...tempRouteDetails);
    return {
      ...route,
      ...capacities,
      NumberOfOrders: getOrderCount(tempRouteDetails),
      NumberOfStops: getStopCount(tempRouteDetails),
    };
  });

  let id = allRouteDetails[0].RouteId;
  let arr = [];
  const coords = allRouteDetails.reduce((acc, curr) => {
    if (curr.RouteId === id) {
      arr.push({ lat: curr.ToLocationLat, lng: curr.ToLocationLong, ...curr });
    } else {
      acc.push(arr);
      id = curr.RouteId;
      arr = [{ lat: curr.ToLocationLat, lng: curr.ToLocationLong, ...curr }];
    }
    return acc;
  }, []);
  coords.push(arr);
  return { routeSummary: result, routeDetails: allRouteDetails, routes: coords };
};
export const calculateNewRouteValues = ({ routeSummary, routeDetails, routes }) => {
  const hasPickup = Boolean(routeDetails.find((item) => item.ServiceType === 'PICKUP'));
  if (hasPickup) return calculateRouteValuesWithPickup({ routeSummary, routeDetails, routes });
  const result = routeSummary.map((route) => {
    try {
      const foundRouteDetails = routeDetails.filter((row) => row.RouteId === route.RouteId);
      return {
        ...route,
        ...getCapacityNewValues(foundRouteDetails, route),
        NumberOfOrders: getOrderCount(foundRouteDetails),
        NumberOfStops: getStopCount(foundRouteDetails),
      };
    } catch (error) {
      return route;
    }
  });
  return { routeSummary: result, routeDetails, routes };
};
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
const addOneDay = (dateTime) => {
  return new Date(new Date(dateTime).setDate(dateTime.getDate() + 1));
};
export const prepareSeriesData = (arr, hideIncomplete, testAccessor, aggregation) => {
  let isFirstValueFound = false;
  return arr.reduce((acc, curr, index) => {
    // start with first nonzero value
    if (curr[testAccessor] > 0) isFirstValueFound = true;
    if (isFirstValueFound) {
      // delete 0 values
      if (curr[testAccessor] === 0 || curr[testAccessor] === null) {
        return acc;
      }
      // set active and hide inactive
      if (index === arr.length - 1) {
        let shouldHide = hideIncomplete;
        if (hideIncomplete && aggregation === 'day') {
          shouldHide = addOneDay(new Date(curr.time)) > new Date();
        }
        return [...acc, { ...curr, active: !shouldHide }];
      }
      return [...acc, { ...curr, active: true }];
    }
    return acc;
  }, []);
};
export const prepareFiltersForTable = (filters, filterTypes = {}, customKeysForFilter, customFormatFunction) => {
  const f = { ...filters };
  let forTable = {};
  Object.keys(deleteEmpty(f)).forEach((k) => {
    if (filterTypes[k] === 'status-multi') {
      forTable = {
        ...forTable,
        [k]: f[k].map((item) => item.value).join(','),
      };
    } else if (filterTypes[k] === 'status-multi-as-array') {
      forTable = {
        ...forTable,
        [k]: f[k].map((item) => item.value),
      };
    } else if (filterTypes[k] === 'select') {
      forTable = {
        ...forTable,
        [k]: f[k].value,
      };
    } else if (filterTypes[k] === 'double_input') {
      forTable = {
        ...forTable,
        ...f[k],
      };
    } else if (filterTypes[k] === 'date_range') {
      const [firstKey, secondKey] = (customKeysForFilter && customKeysForFilter[k]) || ['begin_date', 'end_date'];
      const formatFunction = (customFormatFunction && customFormatFunction[k]) || formatDateWithHyphenReverse;
      forTable = {
        ...forTable,
        [firstKey]: formatFunction(f[k].from, false),
        [secondKey]: formatFunction(f[k].to, false),
      };
    } else if (filterTypes[k] === 'date_single') {
      if (customKeysForFilter) {
        const [firstKey, secondKey] = customKeysForFilter && customKeysForFilter[k];
        forTable = {
          ...forTable,
          [firstKey]: formatDateWithHyphenReverse(f[k], false),
          [secondKey]: formatDateWithHyphenReverse(f[k], false),
        };
      } else {
        forTable = {
          ...forTable,
          [k]: formatDateWithHyphenReverse(f[k], false),
        };
      }
    } else if (filterTypes[k] === 'date_range_simply') {
      const start_date = simplyFormatWithColon(f[k].from, true);
      let end_date = simplyFormatWithColon(f[k].to, true);
      if (start_date === end_date) {
        end_date = simplyFormatWithColon(addDayToDate(f[k].to), true);
      }
      forTable = {
        ...forTable,
        start_date,
        end_date,
      };
    } else if (filterTypes[k] === 'date_range_simply_create') {
      const created_start_date = simplyFormatWithColon(f[k].from, true);
      let created_end_date = simplyFormatWithColon(f[k].to, true);
      if (created_start_date === created_end_date) {
        created_end_date = simplyFormatWithColon(addDayToDate(f[k].to), true);
      }
      forTable = {
        ...forTable,
        created_start_date,
        created_end_date,
      };
    } else {
      forTable = {
        ...forTable,
        [k]: typeof f[k] === 'string' ? f[k].trim() : f[k],
      };
    }
  });
  return forTable;
};

export const decryptSocketData = (ciphertext, key) => {
  try {
    const keyParsed = CryptoJS.enc.Base64.parse(key);
    var bytes = CryptoJS.AES.decrypt(ciphertext, keyParsed, { mode: CryptoJS.mode.ECB });
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return ciphertext;
  }
};
export const encryptText = (message, key) => {
  const keyParsed = CryptoJS.enc.Base64.parse(key);
  return CryptoJS.AES.encrypt(message, keyParsed, { mode: CryptoJS.mode.ECB }).toString();
};

function hexToBytes(hex) {
  var bytes = [];
  for (var i = 0; i < hex.length; i += 2) {
    bytes.push(parseInt(hex.substr(i, 2), 16));
  }
  return new Uint8Array(bytes);
}

export const decryptResponseData = (enc, iv, authTag) => {
  const key = process?.env.REACT_APP_API_DECYRPT_OTHER_KEY;
  if (key) {
    const algo = 'aes-256-gcm';
    const ivParsed = Buffer.from(iv, 'base64');
    const authTagParsed = Buffer.from(authTag, 'base64');
    const decipher = cryptoPolyfill.createDecipheriv(algo, hexToBytes(key), ivParsed);
    decipher.setAuthTag(authTagParsed);
    let str = decipher.update(enc, 'base64', 'utf8');
    str += decipher.final('utf8');
    return JSON.parse(str);
  } else {
    return {};
  }
};

export const successToast = (message = intl.get('success-message'), position = 'bottom-right') =>
  toast.success(message, {
    position,
    autoClose: 5000,
    closeOnClick: true,
    draggable: false,
    pauseOnHover: true,
  });

export const errorToast = (message = intl.get('error'), position = 'bottom-right') =>
  toast.error(message, {
    position,
    autoClose: 5000,
    closeOnClick: true,
    draggable: false,
    pauseOnHover: true,
  });
export const infoToast = (message = 'error', position = 'bottom-right') =>
  toast.info(message, {
    position,
    autoClose: 5000,
    closeOnClick: true,
    draggable: false,
    pauseOnHover: true,
  });

export function deepCompare(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    // Check if both arguments are arrays
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      // Check if arrays have the same length
      if (obj1.length !== obj2.length) {
        return false;
      }
      // Iterate through array elements recursively
      for (let i = 0; i < obj1.length; i++) {
        if (!deepCompare(obj1[i], obj2[i])) {
          return false;
        }
      }
      return true;
    } else {
      // Check if objects have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      // Iterate through object properties recursively
      for (let key of keys1) {
        if (!obj2.hasOwnProperty(key) || !deepCompare(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    }
  } else {
    // If not objects, perform strict equality check
    return obj1 === obj2;
  }
}

export const getPermission = (rbac, name, permissionType) => {
  return rbac.current?.menus && rbac.current.menus[name]?.permissions[permissionType];
};
